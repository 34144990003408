import { useEffect, useState } from 'react';

import { QuizzResults } from 'types';
import { chunkArray } from 'utils';

import ItemSet from './ItemSet';
import ProgressBar from './ProgressBar';
import s from './Quizz.module.scss';

type QuizzProps = {
  questions: string[];
  images?: string[];
  setSize: number;
  scoreLabels: string[];
  onComplete: (scores: QuizzResults) => void;
};

const Quizz: React.FC<QuizzProps> = ({
  questions,
  images,
  onComplete,
  scoreLabels,
  setSize,
}) => {
  const [scores, setScores] = useState<number[]>([]);

  const progress = scores.length / questions.length;

  const [currentSet, setCurrentSet] = useState(0);
  const itemSets = chunkArray(questions, setSize);
  const imageSets = chunkArray(images ?? [], setSize);

  const addScores = (scores: number[]) => setScores(s => s.concat(scores));
  const removeScores = () => setScores(s => s.slice(0, -setSize));

  useEffect(() => {
    setCurrentSet(
      Math.min(itemSets.length - 1, Math.floor(scores.length / setSize)),
    );
  }, [scores, questions, itemSets, setSize]);

  useEffect(() => {
    if (questions.length === scores.length) {
      onComplete(scores.map(
        (answer, i) => ({ questionNumber: i + 1, answer }),
      ));
    }
  }, [questions, scores, onComplete]);


  return (
    <div className={s.container}>
      <ProgressBar progress={progress} />
      <div className={s.items}>
        <ItemSet
          questions={itemSets[currentSet]}
          images={imageSets[currentSet]}
          onAnswers={addScores}
          scoreLabels={scoreLabels}
          isFirstSet={currentSet === 0}
          onBack={removeScores}
        />
      </div>
    </div>
  );
};

export default Quizz;
