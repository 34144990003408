import {
  NetworkError,
  OfflineError,
  ParseResponseError,
  ResponseStatusError,
} from 'errors';
import { logout } from 'appState';

const trimLeadingSlash = (str: string) => str.replace(/^(\/)?/, '');
const appendSlash = (str: string) => str.replace(/(\/)?$/, '').concat('/');

export default class HttpService {
  private checkNetworkStatus = (endpoint: string) => (response: Response) => {
    if (navigator.onLine) {
      return response;
    }
    throw new OfflineError(this.withBaseUrl(endpoint));
  };

  private checkResponseStatus = (endpoint: string) => (response: Response) => {
    if (response.ok) {
      return response;
    }
    if (response.status === 401) {
      logout();
      window.location.replace('/');
    }
    throw new ResponseStatusError(this.withBaseUrl(endpoint), response.status);
  };

  private parseResponse = (endpoint: string) => (response: Response) => {
    if (response.headers.get('Content-Type')?.includes('application/json')) {
      return response.json()
        .catch(() => { throw new ParseResponseError(this.withBaseUrl(endpoint)); });
    }
    return response;
  };

  private fallbackErrorHandler = (endpoint: string) => () => {
    if (!navigator.onLine) {
      throw new OfflineError(this.withBaseUrl(endpoint));
    }
    throw new NetworkError(this.withBaseUrl(endpoint));
  };

  private withBaseUrl(endpoint: string) {
    return appendSlash(this.baseUrl) + trimLeadingSlash(endpoint);
  }

  private accessToken: string | null = null;

  private withHeaders(requestConfig: RequestInit, accessToken?: string) {
    return {
      ...requestConfig,
      headers: {
        ...(requestConfig.headers ?? {}),
        ...(
          accessToken || this.accessToken
            ? { 'Authorization': `Bearer ${accessToken || this.accessToken}` }
            : {}
        ),
        'Content-Type': 'application/json',
      },
    };
  }

  constructor(
    private baseUrl: string,
  ) {}

  setAccessToken = (token: string | null) => {
    this.accessToken = token;
  };

  get<T = unknown>(endpoint: string, accessToken?: string): Promise<T> {
    return fetch(
      this.withBaseUrl(endpoint),
      this.withHeaders({ method: 'GET' }, accessToken),
    )
      .then(this.checkNetworkStatus(endpoint))
      .then(this.checkResponseStatus(endpoint))
      .then(this.parseResponse(endpoint))
      .catch(this.fallbackErrorHandler(endpoint));
  }

  post<T = unknown>(endpoint: string, body: any): Promise<T> {
    return fetch(
      this.withBaseUrl(endpoint),
      this.withHeaders({ method: 'POST', body: JSON.stringify(body) }),
    )
      .then(this.checkNetworkStatus(endpoint))
      .then(this.checkResponseStatus(endpoint))
      .then(this.parseResponse(endpoint))
      .catch(this.fallbackErrorHandler(endpoint));
  }

  patch<T = unknown>(endpoint: string, body: any, accessToken?: string): Promise<T> {
    return fetch(
      this.withBaseUrl(endpoint),
      this.withHeaders({ method: 'PATCH', body: JSON.stringify(body) }, accessToken),
    )
      .then(this.checkNetworkStatus(endpoint))
      .then(this.checkResponseStatus(endpoint))
      .then(this.parseResponse(endpoint))
      .catch(this.fallbackErrorHandler(endpoint));
  }
}
