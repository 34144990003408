export enum AppFlowStage {
  Launch = 'launch',
  AfterLandingPage = 'after-landing page',
  LogIn = 'login',
  LogInByReferral = 'login-by-referral',
  LoggingIn = 'logging in',
  PostLoginInstructions = 'post-login instructions',
  CollectExtraData = 'collect extra data',
  Welcome = 'welcome',
  ProgressMap = 'progress map',
  Game = 'game',
  GamesCompleted = 'games completed',
  PreAssessmentQuestionnaire = 'pre-assessment questionnaire',
  Summary = 'summary',
  PostAssessmentQuestionnaire = 'post-assessment questionnaire',
}
