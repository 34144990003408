import React from 'react';
import s from './GamesCompletedModal.module.scss';
import { ButtonRect, Portal } from '../../../components';
import { useTranslation } from '../../../hooks';
import { TranslationPage } from '../../../types/enums/TranslationPage';

type GamesCompletedModalProps = {
  onClick: () => void;
};

const GamesCompletedModal: React.FC<GamesCompletedModalProps> = ({
  onClick,
}) => {
  const lang = useTranslation(TranslationPage.GAMES_COMPLETED_MODAL);
  return (
    <Portal>
      <div className={s.modalContainer} data-cy='gamesCompletedModal'>
        <div className={s.modal}>
          <h5>{lang.header}</h5>
          <h6>{lang.subHeader}</h6>
          <p>{lang.firstParagraph}</p>
          <p>{lang.secondParagraph}</p>
        </div>
        <ButtonRect
          text={lang.buttonText}
          onClick={onClick}
          isVisible={true}
          className={s.cornerBtn}
        />
      </div>
    </Portal>
  );
};

export default GamesCompletedModal;
