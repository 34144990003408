import { useAppStateActions, useLanguage } from 'appState';
import { ButtonRect } from 'components';
import { useTranslation } from 'hooks';
import amImage from './certificate_am.png';
import enImage from './certificatev2_en.png';
import s from './AfterLandingPage.module.scss';
import { TranslationPage } from '../../types/enums/TranslationPage';
import classnames from 'classnames';
import { useEffect } from 'react';

const AfterLandingPage: React.FC = () => {
  const { appFlowForward, logout } = useAppStateActions();
  const lang = useTranslation(TranslationPage.AFTER_LANDING_PAGE);
  const language = useLanguage();
  const picture = (language: string) => {
    switch (language) {
      case 'en':
        return enImage;
      case 'am':
        return amImage;
      case 'it':
        return enImage;
    }
  };
  // const backgroundUrl = () => `url(${picture(language)})`;

  // Don't leave any possibility of users being left logged in by forcing logout here.
  // Should help to avoid any weird log-in flow bugs
  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <div className={s.container}>
      <div
        className={classnames(s.afterLoginImgContainer, s[language])}
      >
        <img src={picture(language)} alt={"certificate example"} className={s.afterLoginImg}/>
      </div>
      <div className={s.content}>
        <p>{lang.welcomeSecond}</p>
      </div>
      <ButtonRect
        onClick={appFlowForward}
        text={lang.buttonText}
        className={s.startButton}
      />
    </div>
  );
};

export default AfterLandingPage;
