/* eslint-disable no-alert */
import { useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import browserUpdate from 'browser-update';

import s from './OldVersionPrompt.module.scss';
import { TranslationPage } from '../../types/enums/TranslationPage';
import { browserDetectionConfig } from './VersionsList';


const OldVersionPrompt: React.FC = () => {
  const lang = useTranslation(TranslationPage.OLD_VERSION_PROMPT);
  const [promptOpen, setPromptOpen] = useState(false);

  useEffect(() => {
    // Used in separate useEffect to fire only on initial render, ignoring 'data' changes
    browserUpdate({
      ...browserDetectionConfig,
      onshow: () => {setPromptOpen(true)},  // can add options arg if it needs more info on device/browser
    });
  }, []);

  if (!isMobile || !promptOpen) return null;

  const closeView = () => {
    setPromptOpen(false);
  };

  return (
    <div className={s.wrapper}>
      <h1 className={s.title}>
        {lang.title}
      </h1>
      <p className={s.description}>
        {lang.description}
      </p>
      <div className={s.actions}>
        <button
          type="button"
          className={s.buttonOk}
          onClick={closeView}
        >
          {lang.buttonOk}
        </button>
      </div>
    </div>
  );
};

export default OldVersionPrompt;
