import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import isEqual from 'lodash/isEqual';

import { ASPECT_RATIO_WIDTH, ASPECT_RATIO_HEIGHT, INSIDER_BUILD } from 'config';
import {
  AssessmentResults, AssessmentVisibility,
  Dimensions, FeedbackVisibilityOptions,
  GameResults,
  LoginData,
  PdfAssessmentContent,
  PendingGameResults,
  PostAssessmentQuestions,
  PreAssessmentQuestions,
  ResultsId,
  SkippedGameResults,
  VirtualUnits,
} from 'types';
import {
  AppFlowStage,
  GameId,
  Language,
} from 'types/enums';
import api from 'api';
import { uniqueValues } from 'utils';

import { OfflineError } from 'errors';
import { PilotGroupDto } from 'api/dto/PilotGroupDto';
import sampleReportData from './sample-report-data';
import { Tab } from '../AppFlow/Summary/Assessment/enums';

type UserPersistData = {
  isLoggedIn: boolean;
  accessToken: string | null;
  rsaId: string | null;
  collectRsaId: boolean;
  identifier: string;
  identifierType: 'email' | 'rsaId' | 'gepiId'
  identifierHash?: string;
  name?: string;
  number?: string;
  pilotGroup?: string,
  pilotGroupName?: string,
  feedbackVisibilityOptions?: FeedbackVisibilityOptions;
  showFeedback?: boolean;
  gamesToPlay: Array<GameId>;
  completedGames: Array<GameId>;
  pendingResults: Array<PendingGameResults>;
  resultsId: ResultsId | null;
  hasCompletedPrimaryResults: boolean;
  assessmentResults: AssessmentResults | null;
  preAssessmentQuestionsAnswered: boolean;
  postAssessmentQuestionsAnswered: boolean;
};

const SUBMIT_PENDING_RESULTS_INTERVAL = 10000;
const FETCH_PILOT_GROUPS_INTERVAL = 10000;
const GAMES_TO_PLAY_ALL_HARDCODED = [
  GameId.TMT,
  GameId.CORSI,
  GameId.DigitSpan,
  GameId.CPT,
  GameId.MTPT,
  GameId.BRET,
  GameId.RMET,
  GameId.HMT,
  GameId.BigFive,
  GameId.EI,
  GameId.RIASEC,
  GameId.PP,
  GameId.GM,
];

const emptyUserPersistData: UserPersistData = {
  isLoggedIn: false,
  rsaId: null,
  collectRsaId: false,
  accessToken: null,
  name: '',
  identifier: '',
  identifierType: 'email',
  number: '',
  gamesToPlay: GAMES_TO_PLAY_ALL_HARDCODED,
  completedGames: [],
  pendingResults: [],
  resultsId: null,
  pilotGroup: undefined,
  hasCompletedPrimaryResults: false,
  assessmentResults: null,
  preAssessmentQuestionsAnswered: false,
  postAssessmentQuestionsAnswered: false,
};

const defaultFeedbackVisibilityOptions = {
  careerStrengths: true,
  careerInterests: true,
  careerOptionsInterests: true,
  careerOptionsStrengths: true,
  careerLearningCenter: true,
  entrepreneurshipStrengths: true,
  entrepreneurshipCourses: true,
  pdfDownload: true,
  pdfEmail: true,
};

type AppState = {
  errors: unknown[],
  registerError: (error: unknown) => void;

  /*
   * Size of the visible screen portion (minus the address bar on mobile)
   */
  viewportSize: Dimensions;
  setViewportSize: (viewportSize: Dimensions) => void;

  /**
   * Size of the "logical" viewport we use for displaying most content.
   * This viewport always:
   *  * keeps the 16:9 aspect ratio
   *  * occupies as much space as possible within the visible viewport
   *  * never exceeds the visible viewport
   */
  virtualViewportSize: Dimensions;
  virtualUnits: VirtualUnits;

  language: Language;
  resolveDefaultLanguage: (pilotGroupId: string) => void;
  setLanguage: (language: Language) => void;

  isTransitioning: boolean;
  setIsTransitioning: (isTransitioning: boolean) => void;

  currentGameId: () => GameId | null;
  explicitlyRequestedGameId: GameId | null;
  submitGameResults: (gameId: GameId, results: GameResults | SkippedGameResults) => void;
  skipCurrentGame: () => void;

  assessmentResultsPending: boolean;
  assessmentResultsError: boolean;
  getAssessmentIfReady: () => Promise<void>;
  jumpToAssessmentReport: () => void;

  appFlowStage: AppFlowStage;
  appFlowForward: (options?: { explicitGameId?: GameId }) => void;

  displayGamesCompletionModal: boolean;
  gamesCompletionModalDisplayed: boolean;
  onGamesCompletedModalNext: () => void;

  gameFlowStage: number;
  gameFlowReset: () => void;
  gameFlowForward: () => void;

  isSingleGameMode: boolean;
  toggleSingleGameMode: (isSingleGameMode: boolean) => void;

  isSampleReportMode: boolean;
  tryShowingSampleReport: () => void;

  users: Array<UserPersistData>;
  getCurrentUserData: () => UserPersistData | undefined;
  setCurrentUserData: (data: UserPersistData) => void;
  changeLoggedInUser: (identifier: string) => void;
  setChosenUserData: (data: UserPersistData, userIdentifier: string) => void;
  getChosenUserData: (identifier: string) => UserPersistData | null;
  setNewUserData: (data: UserPersistData) => void;
  removeUserFromUsers: (identifier: string) => void;
  removeSentResultsOfUser: (identifier: string) => void;
  getCurrentUserGamesToPlay: () => Array<GameId>;
  getCurrentUserCompletedGames: () => Array<GameId>;
  isGameFlowCompleted: () => boolean;

  pilotGroups: Array<PilotGroupDto>;
  fetchPilotGroups: () => void;
  fetchPilotGroupsInterval: NodeJS.Timeout | number | undefined;
  setFetchPilotGroupsInterval: () => void;

  privacyPolicy: string;
  resolvePrivacyPolicy: (pilotGroupId: string) => void;

  logInMode: 'manual' | 'byReferral';
  resolveLogInMode: (pilotGroupId: string) => void;

  currentUser: string;
  signIn: (user: LoginData) => void;
  signInOffline: (user: LoginData) => void;
  submitExtraDetails: (rsaId: string) => void;
  logout: () => void;

  checkCompletedGames: () => Promise<void>;
  checkCompletedQuestionnaires: () => Promise<void>;
  fetchUser: (user: LoginData) => void;
  submitPendingResults: () => Promise<void>;
  submitPendingResultsInterval?: number | NodeJS.Timeout;
  setSubmitPendingResultsInterval: () => void;
  resetPendingResultsStatus: () => void;

  onAppFlowReady: () => void;

  generateAssessmentPdf: (content: PdfAssessmentContent) => Promise<boolean>;
  generateAssessmentPdfV2: () => Promise<boolean>;
  emailAssessmentPdf: (content: PdfAssessmentContent) => Promise<boolean>;
  emailAssessmentPdfV2: () => Promise<boolean>;
  instructionsStartTime: number,
  startInstructionsTimer: () => void;
  stopInstructionsTimer: () => void;

  submitPreAssessmentQuestionnaire: (questionnaire: PreAssessmentQuestions) => Promise<void>;
  submitPostAssessmentQuestionnaire: (questionnaire: PostAssessmentQuestions) => Promise<void>;

  getAssessmentVisibility: () => AssessmentVisibility;

  selectedAssessmentLearningSection: string | undefined;
  setSelectedAssessmentLearningSection: (name: string) => void;
  assessmentScrollPosition: number | undefined;
  setAssessmentScrollPosition: (position: number) => void;
  activeAssessmentTab: Tab;
  setActiveAssessmentTab: (section: Tab) => void;
};

const useAppState = create<AppState>()(devtools(persist(
  (set, get) => ({
    errors: [],
    registerError: (error) => {
      const serializedError = error instanceof Error
        ? { name: error.name, message: error.message }
        : error;
      set({ errors: get().errors.concat(serializedError) });
    },

    viewportSize: { width: 0, height: 0 },
    setViewportSize: (viewport) => {
      const targetRatio = ASPECT_RATIO_WIDTH / ASPECT_RATIO_HEIGHT;
      const actualRatio = viewport.width / viewport.height;

      const virtualViewport = { width: 0, height: 0 };

      if (actualRatio > targetRatio) {
        virtualViewport.width = viewport.height * targetRatio;
        virtualViewport.height = viewport.height;
      } else {
        virtualViewport.width = viewport.width;
        virtualViewport.height = viewport.width / targetRatio;
      }

      const virtualUnits = {
        w: virtualViewport.width / 100,
        h: virtualViewport.height / 100,
      };

      set({
        viewportSize: viewport,
        virtualViewportSize: virtualViewport,
        virtualUnits,
      });

      document.documentElement.style.fontSize = `${virtualUnits.w}px`;
    },

    virtualViewportSize: { width: 0, height: 0 },
    virtualUnits: { w: 0, h: 0 },

    language: Language.EN,
    setLanguage: (language) => set({ language }),
    resolveDefaultLanguage: async (pilotGroupSlug) => {
      try {
        const groups = await api.getAllPilotGroups();
        const group = groups.find(g => g.slug === pilotGroupSlug);
        if (group) {
          get().setLanguage(group.defaultLanguage || Language.EN);
        }
      } catch {
        get().setLanguage(Language.EN);
      }
    },

    isTransitioning: true,
    setIsTransitioning: isTransitioning => set({ isTransitioning }),

    explicitlyRequestedGameId: null,
    currentGameId: () => {
      const user = get().getCurrentUserData();
      const explicitlyRequestedGameId = get().explicitlyRequestedGameId;
      if (!user) {
        return null;
      }

      if (
        explicitlyRequestedGameId
        && user.gamesToPlay.includes(explicitlyRequestedGameId)
        && !user.completedGames.includes(explicitlyRequestedGameId)
      ) {
        return explicitlyRequestedGameId;
      }

      return user.gamesToPlay.find(game => !user.completedGames.includes(game)) ?? null;
    },
    submitGameResults: async (gameId, results) => {
      const {
        isSingleGameMode,
        setSubmitPendingResultsInterval,
        users,
      } = get();
      const user = get().getCurrentUserData();
      if (!user || !users.length) {
        return;
      }
      if (isSingleGameMode) {
        console.log('%c Game results:', 'font-weight: bold; font-size: 150%'); // eslint-disable-line no-console
        console.log(results); // eslint-disable-line no-console
      } else /* if (user.resultsId) */ {
        const latestResults = {
          gameId,
          results,
          isPending: true,
          isBeingSubmitted: false,
        };
        const completedGames = [...user.completedGames, gameId];
        const hasCompletedAllGames = completedGames.length === user.gamesToPlay.length;
        get().setCurrentUserData({
          ...user,
          completedGames,
          pendingResults: [...user.pendingResults, latestResults],
          hasCompletedPrimaryResults: user.hasCompletedPrimaryResults || hasCompletedAllGames,
        });
        setSubmitPendingResultsInterval();
      }
    },
    skipCurrentGame: () => {
      const gameId = get().currentGameId();
      if (!gameId) return;

      get().submitGameResults(gameId, { gameId, skipped: true });
      get().appFlowForward();
    },

    assessmentResultsPending: false,
    assessmentResultsError: false,
    getAssessmentIfReady: async () => {
      const { /* resultsId, */ registerError } = get();
      const user = get().getCurrentUserData();
      if (!user) {
        return;
      }

      set({ assessmentResultsPending: true });

      api.getPrimaryAssessment()
        .then(assessmentResults => {
          get().setCurrentUserData({ ...user, assessmentResults });
          set({
            assessmentResultsPending: false,
            assessmentResultsError: false,
          });
        })
        .catch(e => {
          registerError(e);
          set({
            assessmentResultsPending: false,
            assessmentResultsError: false,
          });
        });
    },
    jumpToAssessmentReport: () => {
      if (get().getCurrentUserData()?.hasCompletedPrimaryResults) {
        set({ appFlowStage: AppFlowStage.GamesCompleted });
      }
    },

    appFlowStage: AppFlowStage.Launch,
    appFlowForward: async ({ explicitGameId } = { explicitGameId: undefined }) => {
      const {
        checkCompletedGames,
        checkCompletedQuestionnaires,
        getAssessmentIfReady,
        setSubmitPendingResultsInterval,
        isSampleReportMode,
        resetPendingResultsStatus,
      } = get();
      const user = get().getCurrentUserData();
      if (isSampleReportMode) {
        return;
      }

      switch (get().appFlowStage) {
        case AppFlowStage.Launch: {
          if (user?.collectRsaId && !user.rsaId) {
            set({ appFlowStage: AppFlowStage.CollectExtraData });
            break;
          }
          if (user?.isLoggedIn /* && user.accessToken */) {
            if (user.accessToken) {
              api.setToken(user.accessToken);
            }
            await checkCompletedQuestionnaires();
            await checkCompletedGames();
            resetPendingResultsStatus();
            setSubmitPendingResultsInterval();
            getAssessmentIfReady();
            set({ appFlowStage: AppFlowStage.ProgressMap });
          } else {
            set({ appFlowStage: AppFlowStage.Welcome });
          }
          break;
        }
        case AppFlowStage.Welcome: {
          set({ appFlowStage: AppFlowStage.AfterLandingPage });
          break;
        }
        case AppFlowStage.AfterLandingPage: {
          const logInStage = {
            'manual': AppFlowStage.LogIn,
            'byReferral': AppFlowStage.LogInByReferral,
          }[get().logInMode];
          set({ appFlowStage: logInStage });
          break;
        }
        case AppFlowStage.LogIn:
        case AppFlowStage.LogInByReferral: {
          set({ appFlowStage: AppFlowStage.PostLoginInstructions });
          break;
        }
        case AppFlowStage.PostLoginInstructions: {
          if (get().getCurrentUserData()?.collectRsaId && !get().getCurrentUserData()?.rsaId) {
            set({ appFlowStage: AppFlowStage.CollectExtraData });
            break;
          }
          set({ appFlowStage: AppFlowStage.ProgressMap });
          break;
        }
        case AppFlowStage.CollectExtraData: {
          set({ appFlowStage: AppFlowStage.ProgressMap });
          // set({ appFlowStage: AppFlowStage.LoggingIn });
          break;
        }
        case AppFlowStage.ProgressMap: {
          const allGamesCompleted = get().currentGameId() === null;
          const areAllResultsSubmitted = !get().getCurrentUserData()?.pendingResults.length;
          if (allGamesCompleted) {
            // In case finished offline check each 10sec if came back online to proceed
            if (navigator.onLine && areAllResultsSubmitted) {
              set({ appFlowStage: AppFlowStage.GamesCompleted });
            } else {
              set({
                appFlowStage: AppFlowStage.ProgressMap,
                displayGamesCompletionModal: !get().gamesCompletionModalDisplayed,
                gamesCompletionModalDisplayed: true,
              });
              if (!get().displayGamesCompletionModal) {
                setTimeout(() => {
                  get().appFlowForward();
                }, 10000);
              }
            }
          } else {
            set({
              appFlowStage: AppFlowStage.Game,
              explicitlyRequestedGameId: explicitGameId ?? null,
            });
          }
          break;
        }
        case AppFlowStage.Game: {
          set({ appFlowStage: AppFlowStage.ProgressMap });
          get().gameFlowReset();
          break;
        }
        case AppFlowStage.GamesCompleted: {
          // TODO: Temporarily disabled,
          //  restore once options are added in admin panel for this instead
          if (!user?.preAssessmentQuestionsAnswered && false) {
            set({ appFlowStage: AppFlowStage.PreAssessmentQuestionnaire });
          } else {
            set({ appFlowStage: AppFlowStage.Summary });
          }
          break;
        }
        case AppFlowStage.PreAssessmentQuestionnaire: {
          set({ appFlowStage: AppFlowStage.Summary });
          break;
        }
        case AppFlowStage.PostAssessmentQuestionnaire: {
          set({ appFlowStage: AppFlowStage.Summary });
          break;
        }
        case AppFlowStage.Summary: {
          if (!user?.postAssessmentQuestionsAnswered) {
            set({ appFlowStage: AppFlowStage.PostAssessmentQuestionnaire });
          }
          break;
        }
      }
    },

    displayGamesCompletionModal: false,
    gamesCompletionModalDisplayed: false,
    onGamesCompletedModalNext: () => {
      set({
        displayGamesCompletionModal: false,
      });
      get().appFlowForward();
    },

    gameFlowStage: 0,
    gameFlowReset: () => set({ gameFlowStage: 0 }),
    gameFlowForward: () => set({ gameFlowStage: get().gameFlowStage + 1 }),

    isSingleGameMode: false,
    toggleSingleGameMode: (isSingleGameMode) => set({ isSingleGameMode }),

    isSampleReportMode: false,
    tryShowingSampleReport: () => {
      const pathMatch = new URL(window.location.href).pathname.match(/^\/sample-report$/);
      if (INSIDER_BUILD && pathMatch) {
        get().setCurrentUserData({
          ...emptyUserPersistData,
          assessmentResults: sampleReportData,
          name: 'Amahle',
        });
        set({
          isSampleReportMode: true,
          appFlowStage: AppFlowStage.Summary,
        });
      }
    },

    pilotGroups: [],
    fetchPilotGroups: async () => {
      const {
        pilotGroups: prevPilotGroup,
      } = get();
      try {
        const pilotGroups = await api.getAllPilotGroups();
        if (!isEqual(prevPilotGroup, pilotGroups)) {
          set({
            pilotGroups,
          });
        }
      } catch (e) {
        get().registerError(e);
      }
    },
    fetchPilotGroupsInterval: undefined,
    setFetchPilotGroupsInterval: () => {
      clearInterval(get().fetchPilotGroupsInterval);
      get().fetchPilotGroups();
      const interval = setInterval(() => {
        get().fetchPilotGroups();
      }, FETCH_PILOT_GROUPS_INTERVAL);
      set({
        fetchPilotGroupsInterval: interval,
      });
    },

    privacyPolicy: '',
    resolvePrivacyPolicy: async (pilotGroupSlug) => {
      try {
        const groups = get().pilotGroups || await api.getAllPilotGroups();
        const group = groups.find(g => g.slug === pilotGroupSlug);
        const defaultGroup = groups.find(g => g.isDefault);
        if (group || defaultGroup) {
          set({ privacyPolicy: group?.privacyPolicy || defaultGroup?.privacyPolicy || '' });
        }
      } catch {
        const defaultGroup = get().pilotGroups.find(g => g.isDefault);
        set({ privacyPolicy: defaultGroup?.privacyPolicy || '' });
      }
    },

    logInMode: 'manual',
    resolveLogInMode: async (pilotGroupSlug) => {
      const groups = await api.getAllPilotGroups().catch(() => []);
      const group = groups.find(g => g.slug === pilotGroupSlug) || groups.find(g => g.isDefault);
      if (group?.allowAuthByReferral) {
        set({ logInMode: 'byReferral' });
      }
    },

    currentUser: '',
    users: [],
    getCurrentUserData: () => {
      const {
        users,
        currentUser,
      } = get();
      return users.find(item => item.identifier === currentUser);
    },
    setCurrentUserData: (data: UserPersistData) => {
      const {
        users,
        currentUser,
      } = get();
      const index = users.findIndex(item => item.identifier === currentUser);
      if (index === -1) {
        return;
      }
      const newUsersArray = [...users];
      newUsersArray[index] = data;
      set({
        users: newUsersArray,
      });
    },
    changeLoggedInUser: (identifier: string) => {
      const {
        users,
      } = get();
      const index = users.findIndex(item => item.identifier === identifier);
      if (index !== -1) {
        const newUsersArray = [...users];
        newUsersArray.map(item => ({ ...item, isLoggedIn: false }));
        newUsersArray[index].isLoggedIn = true;
        set({
          users: newUsersArray,
          currentUser: identifier,
        });
      }
    },
    setChosenUserData: (data: UserPersistData, userIdentifier: string) => {
      const {
        users,
      } = get();
      const index = users.findIndex(item => item.identifier === userIdentifier);
      if (index !== -1) {
        const newUsersArray = [...users];
        newUsersArray[index] = data;
        set({
          users: newUsersArray,
        });
      }
    },
    getChosenUserData: (identifier: string) => {
      const {
        users,
      } = get();
      const index = users.findIndex(item => item.identifier === identifier);
      return users[index] || null;
    },
    setNewUserData: (data: UserPersistData) => {
      const {
        users,
      } = get();
      const cachedUserIndex = users.findIndex(item => item.identifier === data.identifier);

      if (cachedUserIndex !== -1) {
        const userToSet = users[cachedUserIndex];
        set({
          currentUser: userToSet.identifier,
        });
        get().setCurrentUserData({ ...userToSet, ...data, isLoggedIn: true });
      } else {
        const newUsersArray = [...users];
        newUsersArray.map(item => !item.isLoggedIn);
        newUsersArray.push({ ...data, isLoggedIn: true });
        set({
          currentUser: data.identifier,
          users: newUsersArray,
        });
      }
    },
    removeUserFromUsers: async (identifier: string) => {
      const newUsers = get().users.filter(item => item.identifier !== identifier);
      set({
        users: newUsers,
      });
    },
    removeSentResultsOfUser: async (identifier: string) => {
      const {
        getChosenUserData,
        setChosenUserData,
      } = get();
      const chosenUserData = getChosenUserData(identifier);
      if (!chosenUserData) {
        return;
      }
      const newPendingResults = chosenUserData.pendingResults
        .filter(resultItem => !resultItem.isPending);
      setChosenUserData({ ...chosenUserData, pendingResults: newPendingResults }, identifier);
    },
    getCurrentUserGamesToPlay: () => {
      const currentUser = get().getCurrentUserData();
      const defaultGamesToPlay = get().pilotGroups.find(item => item.isDefault)?.taskOrder;
      return currentUser?.gamesToPlay || defaultGamesToPlay || GAMES_TO_PLAY_ALL_HARDCODED;
    },
    getCurrentUserCompletedGames: () => get().getCurrentUserData()?.completedGames ?? [],
    isGameFlowCompleted: () => {
      const currentUserData = get().getCurrentUserData();
      if (currentUserData) {
        return currentUserData.gamesToPlay.length >= currentUserData.completedGames.length;
      }
      return false;
    },

    signIn: async (user) => {
      try {
        const { accessToken } = await api.signIn(user);
        api.setToken(accessToken);
        // eslint-disable-next-line no-restricted-globals
        const screenSize = `${screen.width}x${screen.height}`;
        await api.updateScreenSize(screenSize);
        const userInfo = await api.getUserInfo();
        const latestResults = await api.getLatestResults();
        get().setNewUserData({
          ...emptyUserPersistData,
          collectRsaId: userInfo.collectRsaId,
          rsaId: userInfo.rsaId,
          pilotGroup: userInfo.pilotGroup,
          pilotGroupName: user.pilotGroup,
          name: user.username,
          number: user.phoneNumber,
          identifier: user.identifier,
          identifierType: user.identifierType,
          identifierHash: user.identifierHash,
          accessToken,
          isLoggedIn: true,
          showFeedback: userInfo.showFeedback,
          feedbackVisibilityOptions: userInfo.feedbackVisibilityOptions,
          resultsId: userInfo.resultId,
          gamesToPlay: userInfo.taskOrder,
          completedGames: latestResults.completedGames,
          preAssessmentQuestionsAnswered: userInfo.preAssessmentQuestionsAnswered,
          postAssessmentQuestionsAnswered: userInfo.postAssessmentQuestionsAnswered,
        });
        get().getAssessmentIfReady();
        get().appFlowForward();
      } catch (e) {
        get().registerError(e);
        if (e instanceof OfflineError) {
          get().signInOffline(user);
        }
      }
    },
    signInOffline: (user) => {
      const {
        pilotGroups,
        users,
        changeLoggedInUser,
        appFlowForward,
        setNewUserData,
      } = get();
      const pilot = pilotGroups.find(item => user.pilotGroup === item['_id']
        || item.slug === user.pilotGroup);
      const defaultPilot = pilotGroups.find(item => item.isDefault);
      const taskOrder = pilot?.taskOrder
        || defaultPilot?.taskOrder
        || emptyUserPersistData.gamesToPlay;
      let showFeedback = true;
      if (pilot && 'showFeedback' in pilot) {
        showFeedback = pilot.showFeedback;
      } else if (defaultPilot && 'showFeedback' in defaultPilot) {
        showFeedback = defaultPilot.showFeedback;
      }
      const feedbackVisibilityOptions = pilot?.feedbackVisibilityOptions
        || defaultPilot?.feedbackVisibilityOptions
        || defaultFeedbackVisibilityOptions;
      const cachedUser = users.find(item => item.identifier === user.identifier);
      if (cachedUser) {
        changeLoggedInUser(user.identifier);
      } else {
        setNewUserData({
          ...emptyUserPersistData,
          name: user.username || '',
          identifier: user.identifier,
          identifierType: user.identifierType,
          number: user.phoneNumber || '',
          pilotGroupName: user.pilotGroup,
          identifierHash: user.identifierHash,
          isLoggedIn: true,
          gamesToPlay: taskOrder,
          showFeedback,
          feedbackVisibilityOptions,
        });
      }
      appFlowForward();
    },

    submitExtraDetails: async (rsaId) => {
      const user = get().getCurrentUserData();
      if (!user) {
        return;
      }
      get().setCurrentUserData({ ...user, rsaId });
      get().appFlowForward();
      try {
        await api.postExtraDetails(rsaId);
      } catch (e) {
        get().registerError(e);
        if (e instanceof OfflineError) {
          // TODO: make sure we retry submitting the extra data later on
        }
      }
      // set({ appFlowStage: AppFlowStage.ProgressMap });
    },

    logout: () => {
      const currentUser = get().getCurrentUserData();
      if (currentUser) {
        get().setCurrentUserData({
          ...currentUser,
          isLoggedIn: false,
          accessToken: null,
        });
      }
      set({
        assessmentResultsError: false,
        assessmentResultsPending: false,
        currentUser: '',
      });
      api.setToken(null);
    },

    checkCompletedQuestionnaires: async () => {
      try {
        const user = get().getCurrentUserData();
        if (!user) {
          return;
        }
        const userInfo = await api.getUserInfo();
        get().setCurrentUserData({
          ...user,
          pilotGroup: userInfo.pilotGroup,
          showFeedback: userInfo.showFeedback,
          feedbackVisibilityOptions: userInfo.feedbackVisibilityOptions,
          preAssessmentQuestionsAnswered: userInfo.preAssessmentQuestionsAnswered,
          postAssessmentQuestionsAnswered: userInfo.postAssessmentQuestionsAnswered,
        });
      } catch (e) {
        get().registerError(e);
      }
    },
    checkCompletedGames: async () => {
      try {
        const latestResults = await api.getLatestResults();
        const user = get().getCurrentUserData();
        if (!user) {
          return;
        }
        const completedGames = uniqueValues(
          user.completedGames,
          latestResults.completedGames,
        );
        get().setCurrentUserData({
          ...user,
          completedGames,
          resultsId: latestResults.resultsId,
          hasCompletedPrimaryResults: latestResults.hasCompletedPrimaryResults,
        });
      } catch (e) {
        get().registerError(e);
      }
    },

    fetchUser: async (user: LoginData) => {
      const cachedUserData = get().getChosenUserData(user.identifier);
      if (!cachedUserData) {
        return;
      }
      let accessToken: string | null;
      try {
        const response = await api.signIn(user);
        accessToken = response.accessToken;
      } catch (e) {
        get().registerError(e);
        accessToken = null;
      }
      if (cachedUserData.identifier === get().currentUser && cachedUserData.isLoggedIn) {
        api.setToken(accessToken);
      }
      let userInfo = null;
      if (accessToken) {
        userInfo = await api.getUserInfo(accessToken);
      }
      get().setChosenUserData({
        ...cachedUserData,
        accessToken,
        showFeedback: userInfo?.showFeedback || cachedUserData.showFeedback,
        feedbackVisibilityOptions: userInfo?.feedbackVisibilityOptions
          || cachedUserData.feedbackVisibilityOptions,
        pilotGroup: userInfo?.pilotGroup || cachedUserData.pilotGroup,
        resultsId: userInfo?.resultId || null,
        preAssessmentQuestionsAnswered: userInfo?.preAssessmentQuestionsAnswered
          || cachedUserData.preAssessmentQuestionsAnswered,
        postAssessmentQuestionsAnswered: userInfo?.postAssessmentQuestionsAnswered
          || cachedUserData.postAssessmentQuestionsAnswered,
      }, cachedUserData.identifier);
    },
    // TODO: Should probably be refactored/simplified
    submitPendingResults: async () => {
      const {
        registerError,
        getAssessmentIfReady,
        removeUserFromUsers,
      } = get();

      // Helper method for setting status of chosen results
      const setResultsStatus = (
        gameId: GameId,
        status: 'isBeingSubmitted' | 'isPending',
        statusValue: boolean,
        identifier: string,
      ) => {
        const chosenUser = get().getChosenUserData(identifier);
        if (!chosenUser) {
          return;
        }
        get().setChosenUserData({
          ...chosenUser,
          pendingResults: chosenUser.pendingResults.map(results => (
            results.gameId === gameId
              ? { ...results, [status]: statusValue }
              : results
          )),
        }, identifier);
      };

      // For storing users for removal
      let usersToRemove: Array<string> = [];

      get().users.map(async (cachedUserData): Promise<void> => {
        // Fetch any data of cached user that could be missing
        if (!cachedUserData.resultsId || !cachedUserData.accessToken) {
          if (cachedUserData.identifier) {
            const cachedUser: LoginData = {
              identifier: cachedUserData.identifier,
              identifierType: cachedUserData.identifierType,
              pilotGroup: cachedUserData.pilotGroupName,
            };
            if (cachedUserData.name) {
              cachedUser.username = cachedUserData.name
            }
            if (cachedUserData.number) {
              cachedUser.phoneNumber = cachedUserData.number;
            }
            await get().fetchUser(cachedUser);
          }
        }
        const updatedUser = get().getChosenUserData(cachedUserData.identifier); /// getuser after fetching updated info
        const resultsId = updatedUser?.resultsId;
        if (updatedUser && resultsId) {
          // Assume cached user will be removed
          // and set removeUser flag only on results setting error
          // or if user still has pending results
          usersToRemove.push(cachedUserData.identifier);

          let removeUser = true;
          updatedUser.pendingResults
            .filter(({ isPending, isBeingSubmitted }) => isPending && !isBeingSubmitted)
            .forEach(({ gameId, results }) => {
              setResultsStatus(gameId, 'isBeingSubmitted', true, cachedUserData.identifier);
              api.postResults(resultsId, results, updatedUser.accessToken || undefined)
                .then(() => {
                  setResultsStatus(gameId, 'isBeingSubmitted', false, updatedUser.identifier);
                  setResultsStatus(gameId, 'isPending', false, updatedUser.identifier);
                })
                .catch(e => {
                  registerError(e);
                  setResultsStatus(gameId, 'isBeingSubmitted', false, updatedUser.identifier);
                  removeUser = false;
                })
                .finally(() => {
                  getAssessmentIfReady();
                });
            });
          const resultsStillPending = updatedUser.pendingResults.filter((value) =>
            value.isPending || value.isBeingSubmitted
          );
          get().setChosenUserData(
            { ...updatedUser, pendingResults: resultsStillPending },
            updatedUser.identifier,
          );
          if (!removeUser || !!resultsStillPending.length) {
            usersToRemove = usersToRemove.filter(id => cachedUserData.identifier !== id)
          }
        }
      });
      // Remove cached users marked in previous map operations
      const currentUser = get().currentUser;
      usersToRemove
        .filter((item: string | undefined) => item !== undefined)
        .filter(item => item !== currentUser)
        .forEach(item => {removeUserFromUsers(item || "")})
    },
    submitPendingResultsInterval: undefined,
    setSubmitPendingResultsInterval: () => {
      clearInterval(get().submitPendingResultsInterval);
      get().submitPendingResults();
      const interval = setInterval(() => {
        get().submitPendingResults();
      }, SUBMIT_PENDING_RESULTS_INTERVAL);
      set({
        submitPendingResultsInterval: interval,
      });
    },
    resetPendingResultsStatus: () => {
      const { users } = get();
      const updatedUsers = users.map(user => ({
        ...user,
        pendingResults: user.pendingResults.map(result => ({
          ...result,
          isBeingSubmitted: false,
        })),
      }));
      set({ users: updatedUsers });
    },

    onAppFlowReady: async () => {
      const {
        setSubmitPendingResultsInterval,
        getCurrentUserData,
      } = get();
      const currentUser = getCurrentUserData();
      if (!!currentUser) {
        const url = new URL(window.location.href);
        const pathPilot = url.pathname.split('/').filter(Boolean)[0];
        const paramId = url.searchParams.get("id");
        const paramH = url.searchParams.get("h");
        const isNewId = !!paramId && paramId !== currentUser.identifier
        const isNewH = !!paramH && paramH !== currentUser.identifierHash
        // Check if current ref link changes active user
        if (
          pathPilot !== currentUser.pilotGroupName
          || isNewId
          || isNewH
        ) {
          get().logout();
          // set the interval in case pending results can be submitted on welcome screen
          setSubmitPendingResultsInterval();
          return;
        }
      }
      api.setToken(get().getCurrentUserData()?.accessToken || null);
      setSubmitPendingResultsInterval();
    },

    instructionsStartTime: 0,
    startInstructionsTimer: () => {
      set({
        instructionsStartTime: new Date().getTime(),
      });
    },
    stopInstructionsTimer: () => {
      set({
        instructionsStartTime: new Date().getTime() - get().instructionsStartTime,
      });
    },

    generateAssessmentPdf: async (content) => {
      const { registerError } = get();
      const user = get().getCurrentUserData();
      if (!user?.resultsId) return false;

      try {
        await api.generateAssessmentPdf(user.resultsId, content);
        return true;
      } catch (e) {
        registerError(e);
        return false;
      }
    },

    generateAssessmentPdfV2: async () => {
      const { registerError, language } = get();
      const user = get().getCurrentUserData();
      if (!user?.resultsId) return false;

      try {
        await api.generateAssessmentPdfV2(user.resultsId, language);
        return true;
      } catch (e) {
        registerError(e);
        return false;
      }
    },

    emailAssessmentPdf: async (content) => {
      const { registerError } = get();
      const user = get().getCurrentUserData();
      if (!user?.resultsId) return false;

      try {
        await api.emailAssessmentPdf(user.resultsId, content);
        return true;
      } catch (e) {
        registerError(e);
        return false;
      }
    },

    emailAssessmentPdfV2: async () => {
      const { registerError, language } = get();
      const user = get().getCurrentUserData();
      if (!user?.resultsId) return false;

      try {
        await api.emailAssessmentPdfV2(user.resultsId, language);
        return true;
      } catch (e) {
        registerError(e);
        return false;
      }
    },

    submitPreAssessmentQuestionnaire: async (questionnaire) => {
      const user = get().getCurrentUserData();
      if (!user) {
        return;
      }
      return api.submitPreAssessmentQuestionnaire(questionnaire)
        .then(() => {
          get().setCurrentUserData({ ...user, preAssessmentQuestionsAnswered: true });
          get().appFlowForward();
        })
        .catch(() => {
          get().setCurrentUserData({ ...user, preAssessmentQuestionsAnswered: false });
          get().appFlowForward();
        });
    },

    submitPostAssessmentQuestionnaire: async (questionnaire) => {
      const user = get().getCurrentUserData();
      if (!user) {
        return;
      }
      return api.submitPostAssessmentQuestionnaire(questionnaire)
        .then(() => {
          get().setCurrentUserData({ ...user, postAssessmentQuestionsAnswered: true });
          get().appFlowForward();
        })
        .catch(() => {
          get().setCurrentUserData({ ...user, postAssessmentQuestionsAnswered: false });
          get().appFlowForward();
        });
    },

    getAssessmentVisibility: () => {
      const currentUserData = get().getCurrentUserData();
      const pilot = get().pilotGroups.find(item => item.isDefault);
      let showFeedback = true;
      if (currentUserData && 'showFeedback' in currentUserData) {
        showFeedback = currentUserData.showFeedback!;
      } else if (pilot && 'showFeedback' in pilot) {
        showFeedback = pilot.showFeedback!;
      }
      let feedbackVisibilityOptions = defaultFeedbackVisibilityOptions;
      if (currentUserData && 'feedbackVisibilityOptions' in currentUserData) {
        feedbackVisibilityOptions = currentUserData.feedbackVisibilityOptions!;
      } else if (pilot && 'feedbackVisibilityOptions' in pilot) {
        feedbackVisibilityOptions = pilot.feedbackVisibilityOptions!;
      }
      return {
        showFeedback,
        feedbackVisibilityOptions,
      };
    },

    selectedAssessmentLearningSection: '',
    setSelectedAssessmentLearningSection: (name: string) => {
      set({
        selectedAssessmentLearningSection: name,
      });
      setTimeout(() => {
        set({
          selectedAssessmentLearningSection: undefined,
        });
      }, 1000);
    },
    assessmentScrollPosition: 0,
    setAssessmentScrollPosition: (position: number) => {
      set({
        assessmentScrollPosition: position,
      });
      // clear so that state change is detected on each set
      setTimeout(() => {
        set({
          assessmentScrollPosition: undefined,
        });
      }, 1000);
    },
    activeAssessmentTab: Tab.Career,
    setActiveAssessmentTab: (section: Tab) => {
      set({
        activeAssessmentTab: section,
      });
    },
  }),
  {
    name: 'appState',
    partialize: (state) => Object.fromEntries(
      Object.entries(state as object).filter(([key, _]) => [
        'users',
        'currentUser',
        'pilotGroups',
      ].includes(key)),
    ),
  },
)));

// const useAppState = createAppState();

// export {
//   createAppState,
// };
export default useAppState;
