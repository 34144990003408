import { API_URL } from 'config';
import {
  AssessmentResults,
  GameResults,
  LoginData,
  PdfAssessmentContent,
  PostAssessmentQuestions,
  PreAssessmentQuestions,
  ResultsId,
  SkippedGameResults,
} from 'types';
import { GameId, Language } from 'types/enums';

import { Visitation } from 'types/Visitation';
import HttpService from './HttpService';
import { ResultsDto, SignInDto, UserDto } from './dto';
import { PilotGroupDto } from './dto/PilotGroupDto';

export default class ApiService {
  private http = new HttpService(API_URL);

  setToken = this.http.setAccessToken;

  signIn(data: LoginData) {
    return this.http.post<SignInDto>('auth/signin', data);
  }

  async getAllPilotGroups() {
    return this.http.get<Array<PilotGroupDto>>('pilot-group/all');
  }

  async getUserInfo(accessToken?: string) {
    const {
      taskOrder,
      resultId,
      collectRsaId,
      rsaId,
      pilotGroup,
      feedbackVisibilityOptions,
      showFeedback,
      preAssessmentQuestionsAnswered,
      postAssessmentQuestionsAnswered,
    } = await this.http.get<UserDto>('user', accessToken);
    return {
      taskOrder,
      resultId,
      collectRsaId,
      rsaId,
      pilotGroup,
      showFeedback,
      feedbackVisibilityOptions,
      preAssessmentQuestionsAnswered: preAssessmentQuestionsAnswered ?? false,
      postAssessmentQuestionsAnswered: postAssessmentQuestionsAnswered ?? false,
    };
  }

  async postExtraDetails(rsaId: string) {
    await this.http.patch('user/update', { rsaId });
  }

  async updateScreenSize(screenSize: string) {
    await this.http.patch('user/update', { screenSize });
  }

  async getLatestResults() {
    const {
      _id: resultsId,
      primary,
      ...results
    } = await this.http.get<ResultsDto>('result');
    // We're not interested in detailed results, let's just check
    // which games have *some* results already:
    const skipped = results.skippedGames ?? [];
    const completedGames = [
      (results.tmtMetadata || skipped.includes(GameId.TMT)) && GameId.TMT,
      (results.corsiGameScores || skipped.includes(GameId.CORSI)) && GameId.CORSI,
      (results.digitSpanScores || skipped.includes(GameId.DigitSpan)) && GameId.DigitSpan,
      (results.cptGameScores || skipped.includes(GameId.CPT)) && GameId.CPT,
      (results.mtptScores || skipped.includes(GameId.MTPT)) && GameId.MTPT,
      (results.bretGameScores || skipped.includes(GameId.BRET)) && GameId.BRET,
      (results.rmetScores || skipped.includes(GameId.RMET)) && GameId.RMET,
      (results.hmtScores || skipped.includes(GameId.HMT)) && GameId.HMT,
      (results.bigFiveTestScores || skipped.includes(GameId.BigFive)) && GameId.BigFive,
      (results.emotionalTestScores || skipped.includes(GameId.EI)) && GameId.EI,
      (results.riasecTestScores || skipped.includes(GameId.RIASEC)) && GameId.RIASEC,
      (results.proactiveTestScores || skipped.includes(GameId.PP)) && GameId.PP,
      (results.growthMindsetTestScores || skipped.includes(GameId.GM)) && GameId.GM,
    ].filter(id => !!id);

    return {
      resultsId,
      completedGames,
      // If the latest result is not marked as primary, this means
      // the user must have completed all the games at least once
      // some time before
      hasCompletedPrimaryResults: !primary,
    };
  }

  async postResults(
    resultsId: ResultsId,
    results: GameResults | SkippedGameResults,
    accessToken?: string,
  ) {
    let data = results as unknown as Omit<ResultsDto, '_id' | 'primary'>;
    if ('skipped' in results) {
      data = {
        skippedGames: [ results.gameId ]
      };
    }

    return this.http.patch<ResultsDto>(`result/${resultsId}/update`, data, accessToken);
  }

  async getAssessment(resultsId: ResultsId) {
    return this.http.get<AssessmentResults>(`result/${resultsId}/assessment`);
  }

  async getPrimaryAssessment() {
    return this.http.get<AssessmentResults>('result/primary-assessment');
  }

  async generateAssessmentPdf(resultsId: ResultsId, content: PdfAssessmentContent) {
    return this.http.post<any>(`result/${resultsId}/assessment/pdf`, content)
      .then((response: Response) => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'assessment.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  async generateAssessmentPdfV2(resultsId: ResultsId, language: Language) {
    return this.http.get<Response>(`result/${resultsId}/assessment/pdf2/${language}`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'assessment.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  async emailAssessmentPdf(resultsId: ResultsId, content: PdfAssessmentContent) {
    return this.http.post<void>(`result/${resultsId}/assessment/email-pdf`, content);
  }

  async emailAssessmentPdfV2(resultsId: ResultsId, language: Language) {
    return this.http.get<void>(`result/${resultsId}/assessment/email-pdf2/${language}`);
  }

  async submitPreAssessmentQuestionnaire(questionnaire: PreAssessmentQuestions) {
    const payload = {
      ...questionnaire,
      preAssessmentQuestionsAnswered: true,
    };
    return this.http.patch('user/update', payload);
  }

  async submitPostAssessmentQuestionnaire(questionnaire: PostAssessmentQuestions) {
    const payload = {
      ...questionnaire,
      postAssessmentQuestionsAnswered: true,
    };
    return this.http.patch('user/update', payload);
  }

  async createNewVisitation() {
    return this.http.post<Visitation>('user/visitations/create', {});
  }

  async updateVisitation(sessionId: number, content: Partial<Visitation>) {
    return this.http.patch<Visitation>(`user/visitations/${sessionId}`, content);
  }
}
