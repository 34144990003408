import { Language } from 'types/enums';

import useAppState from './useAppState';
import { useShallow } from 'zustand/react/shallow';

export const useAppStateActions = () => useAppState(useShallow(s => ({
  setViewportSize: s.setViewportSize,
  setLanguage: s.setLanguage,
  resolveDefaultLanguage: s.resolveDefaultLanguage,
  resolvePrivacyPolicy: s.resolvePrivacyPolicy,
  resolveLogInMode: s.resolveLogInMode,
  setIsTransitioning: s.setIsTransitioning,
  appFlowForward: s.appFlowForward,
  gameFlowReset: s.gameFlowReset,
  gameFlowForward: s.gameFlowForward,
  submitGameResults: s.submitGameResults,
  skipCurrentGame: s.skipCurrentGame,
  tryShowingSampleReport: s.tryShowingSampleReport,
  logout: s.logout,
  signIn: s.signIn,
  submitExtraDetails: s.submitExtraDetails,
  onAppFlowReady: s.onAppFlowReady,
  generateAssessmentPdf: s.generateAssessmentPdfV2,
  emailAssessmentPdf: s.emailAssessmentPdfV2,
  collectRsaId: s.getCurrentUserData()?.collectRsaId,
  submitPreAssessmentQuestionnaire: s.submitPreAssessmentQuestionnaire,
  submitPostAssessmentQuestionnaire: s.submitPostAssessmentQuestionnaire,
  jumpToAssessmentReport: s.jumpToAssessmentReport,
  setFetchPilotGroupsInterval: s.setFetchPilotGroupsInterval,
  setSubmitPendingResultsInterval: s.setSubmitPendingResultsInterval,
  setAssessmentScrollPosition: s.setAssessmentScrollPosition,
  setSelectedAssessmentLearningSection: s.setSelectedAssessmentLearningSection,
  onGamesCompletedModalNext: s.onGamesCompletedModalNext,
  startInstructionsTimer: s.startInstructionsTimer,
  stopInstructionsTimer: s.stopInstructionsTimer,
})));

export const useViewportSize = () => useAppState(s => s.viewportSize);
export const useVirtualViewportSize = () => useAppState(s => s.virtualViewportSize);
export const useVirtualUnits = () => useAppState(s => s.virtualUnits);
export const useLanguage = () => useAppState(s => s.language);
export const useLanguageSwith = (lang: Language) => useAppState(s => s.setLanguage(lang));
export const useIsTransitioning = () => useAppState(s => s.isTransitioning);
export const useGamesToPlay = () => useAppState(s => s.getCurrentUserGamesToPlay());
export const useCurrentGameId = () => useAppState(s => s.currentGameId());
export const useIsGameFlowCompleted = () => useAppState(
  s => s.isGameFlowCompleted(),
);
export const useAppFlowStage = () => useAppState(s => s.appFlowStage);
export const useGameFlowStage = () => useAppState(s => s.gameFlowStage);
export const useAssessmentResults = () =>
  useAppState(s => s.getCurrentUserData()?.assessmentResults);
export const useAssessmentVisibility = () =>
  useAppState(s => s.getAssessmentVisibility());
export const useUsername = () => useAppState(s => s.getCurrentUserData()?.name || '');
export const useCollectRsaId = () => useAppState(s => s.getCurrentUserData()?.collectRsaId);
export const usePreAssessmentQuestionsAnswered = () =>
  useAppState(s => !!s.getCurrentUserData()?.preAssessmentQuestionsAnswered);
export const usePostAssessmentQuestionsAnswered = () =>
  useAppState(s => !!s.getCurrentUserData()?.postAssessmentQuestionsAnswered);
export const useShowFeedback = () =>
  useAppState(s => !!s.getCurrentUserData()?.showFeedback);
export const useDisplayGamesCompletedModal = () =>
  useAppState(s => s.displayGamesCompletionModal);
