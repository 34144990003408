import { create } from 'zustand';

import { MTPTStageResult, Point } from './types';
import { useShallow } from 'zustand/react/shallow';

const clientCoordsToRelative = (
  gameArea: HTMLDivElement,
  clientX: number,
  clientY: number,
) => {
  const area = gameArea.getBoundingClientRect();
  return {
    x: (clientX - area.left) / area.width,
    y: (clientY - area.top) / area.height,
  };
};

type GameState = {
  start: (startingPoint: Point, totalTime: number) => void;
  cleanup: () => void;
  quit: () => void;
  getResults: () => Omit<MTPTStageResult, 'isPractice'>;
  maxRoundTime: number;
  gameTimeoutRef: number;
  intervalRef: number;
  gameStart: number;
  remainingTime: number;

  gameAreaRef: HTMLDivElement | null;
  setGameAreaRef: (ref: HTMLDivElement) => void;

  isTracking: boolean;
  isCompleted: boolean;
  hasError: boolean;
  didHesitate: boolean;
  didTimeOut: boolean;
  didQuit: boolean;
  clickPoint: Point;
  trackedPoints: Point[];

  startTracking: (e: React.PointerEvent) => void;
  track: (e: React.PointerEvent) => void;
  cancelTracking: () => void;
  onTrackingError: () => void;

  hesitationTimeout: number;
  hesitationTimeoutRef: number;
  resetHesitationTimeout: () => void;
  onHesitationTimeout: () => void;
  onGameTimeout: () => void;

  maxProgress: number;
  setProgress: (progress: number) => void;
  timesTooSlow: number;
  errors: number;
};

const useGameState = create<GameState>()((set, get) => ({
  start: (startingPoint, totalTime) => {
    clearInterval(get().intervalRef);
    clearTimeout(get().gameTimeoutRef);

    const intervalRef = window.setInterval(
      () => set({
        remainingTime: Math.max(0, get().remainingTime - 1000),
        maxRoundTime: totalTime,
      }),
      1000,
    );

    const gameTimeoutRef = window.setTimeout(
      get().onGameTimeout,
      totalTime,
    );
    set({
      maxRoundTime: 0,
      gameTimeoutRef,
      intervalRef,
      remainingTime: totalTime,
      gameStart: Date.now(),
      trackedPoints: [startingPoint],
      clickPoint: { x: 0, y: 0 },
      isTracking: false,
      isCompleted: false,
      hasError: false,
      didHesitate: false,
      didTimeOut: false,
      didQuit: false,
      maxProgress: 0,
      timesTooSlow: 0,
      errors: 0,
    });
  },

  cleanup: () => {
    clearInterval(get().intervalRef);
    clearTimeout(get().gameTimeoutRef);
    clearTimeout(get().hesitationTimeoutRef);
  },

  quit: () => {
    get().cleanup();
    set({
      didQuit: true,
    });
  },

  getResults: () => {
    const {
      errors,
      timesTooSlow,
      maxProgress,
      didQuit,
      gameStart,
      maxRoundTime,
    } = get();
    return {
      nrTimesOffLine: errors,
      nrTimesTooSlow: timesTooSlow,
      totalErrors: errors + timesTooSlow,
      roundTime: Math.min(maxRoundTime, Date.now() - gameStart),
      maxRoundTime,
      maxCompletionPercent: maxProgress,
      didQuit,
    };
  },

  maxRoundTime: 0,
  gameStart: Date.now(),
  elapsedTime: 0,
  gameTimeoutRef: -1,
  intervalRef: -1,
  remainingTime: 0,

  gameAreaRef: null,
  setGameAreaRef: (gameAreaRef) => set({ gameAreaRef }),

  isTracking: false,
  isCompleted: false,
  hasError: false,
  didHesitate: false,
  didTimeOut: false,
  didQuit: false,
  clickPoint: { x: 0, y: 0 },
  trackedPoints: [],

  startTracking: ({ clientX, clientY }) => {
    const {
      hasError,
      gameAreaRef,
      resetHesitationTimeout,
      didTimeOut,
    } = get();
    if (hasError || !gameAreaRef || didTimeOut) return;
    const clickPoint = clientCoordsToRelative(gameAreaRef, clientX, clientY);

    resetHesitationTimeout();
    set({
      clickPoint,
      isTracking: true,
    });
  },

  track: ({ clientX, clientY }) => {
    const {
      hasError,
      isCompleted,
      gameAreaRef,
      clickPoint,
      isTracking,
      trackedPoints,
      resetHesitationTimeout,
      didTimeOut,
      didQuit,
    } = get();
    if (
      isCompleted
      || hasError
      || didTimeOut
      || didQuit
      || !isTracking
      || !gameAreaRef
      || !clickPoint) return;

    resetHesitationTimeout();

    const { x, y } = clientCoordsToRelative(gameAreaRef, clientX, clientY);
    const initialPoint = trackedPoints[0];

    // The higher this value, the faster the cursor moves relative to the input movement
    const modifier = 1.5;

    const offsetX = clickPoint.x - x;
    const mirrorX = x + offsetX * 2 * modifier;

    const offsetY = clickPoint.y + (y - clickPoint.y) * modifier;

    const mirrorPoint = {
      x: mirrorX + (initialPoint.x - clickPoint.x),
      y: offsetY + (initialPoint.y - clickPoint.y),
    };
    set({ trackedPoints: [...trackedPoints, mirrorPoint] });
  },

  cancelTracking: () => {
    const {
      isTracking,
      isCompleted,
      didTimeOut,
      didQuit,
      hesitationTimeoutRef,
    } = get();
    if (!isTracking || isCompleted || didTimeOut || didQuit) return;
    clearTimeout(hesitationTimeoutRef);
    set({
      isTracking: false,
      trackedPoints: get().trackedPoints.slice(0, 1),
    });
  },

  hesitationTimeout: 2000,
  hesitationTimeoutRef: -1,

  resetHesitationTimeout: () => {
    const {
      hesitationTimeout,
      hesitationTimeoutRef,
      onHesitationTimeout,
    } = get();
    clearTimeout(hesitationTimeoutRef);
    set({
      hesitationTimeoutRef: window.setTimeout(onHesitationTimeout, hesitationTimeout),
      didHesitate: false,
    });
  },

  onHesitationTimeout: () => {
    const {
      hasError,
      isCompleted,
      didTimeOut,
      onTrackingError,
      timesTooSlow,
      didQuit,
    } = get();
    if (isCompleted || hasError || didTimeOut || didQuit) return;
    onTrackingError();
    set({
      didHesitate: true,
      timesTooSlow: timesTooSlow + 1,
    });
  },

  onGameTimeout: () => {
    const {
      isCompleted,
      didQuit,
    } = get();
    if (isCompleted || didQuit) return;
    set({
      didTimeOut: true,
      didHesitate: false,
    });
  },

  onTrackingError: () => {
    if (!get().isTracking || get().isCompleted || get().didQuit) return;
    get().cancelTracking();
    set({
      hasError: true,
      errors: get().errors + 1,
    });
    setTimeout(
      () => set({ hasError: false }),
      1000,
    );
  },

  maxProgress: 0,
  setProgress: (progress) => {
    const maxProgress = Math.max(progress, get().maxProgress);
    const isCompleted = maxProgress === 1;
    if (isCompleted) {
      clearTimeout(get().gameTimeoutRef);
      get().cleanup();
    }
    set({
      maxProgress,
      isCompleted,
    });
  },

  timesTooSlow: 0,
  errors: 0,
}));

const useSelectors = () => useGameState(useShallow(s => ({
  start: s.start,
  cleanup: s.cleanup,
  quit: s.quit,
  getResults: s.getResults,
  remainingTime: s.remainingTime,
  setGameAreaRef: s.setGameAreaRef,
  startTracking: s.startTracking,
  track: s.track,
  cancelTracking: s.cancelTracking,
  onTrackingError: s.onTrackingError,
  trackedPoints: s.trackedPoints,
  hasError: s.hasError,
  isTracking: s.isTracking,
  isCompleted: s.isCompleted,
  setProgress: s.setProgress,
  didHesitate: s.didHesitate,
  didTimeOut: s.didTimeOut,
  didQuit: s.didQuit,
})));

export default useSelectors;
